.businesses-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}

.business-item {
  box-sizing: border-box;
  background-color: #323232;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.modal {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  border: solid white;
  border-width: 1px;
  border-radius: 60px 60px 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  background-color: #282828;
  transform: translateY(100%);
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  opacity: 0;
}

.overlay-safe.hidden{
  display: none;
}

.modal.visible {
  transform: translateY(0%);
  opacity: 1;
}

.modal.hidden {
  transform: translateY(100%);
  opacity: 0;
}

.overlay {
  z-index: 99;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: black;
  opacity: 0.3;
  margin-top: 100px;
}

.overlay-safe {
  margin-top: 100px;

  z-index: 99;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}
