@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.cdnfonts.com/css/gilroy-bold');

html,
body {
  /* height: 100%; */
}

body {
  margin: 0;
  font-family: Gilroy-Medium;
  font-weight: 550;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #1c1c1c;
  /* height: calc(100vh - 100px)!important; */
}

#root{
  height: calc(100vh - 81px);
  user-select: none;
}
